import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | Palm House Hospitality",
  pageDescription:
    "A multi-concept hospitality group finds a tech solution for every niche.",
  title: "Client-first, Trustworthy Tech",
  subTitle: `See how Palm House Hospitality increased check averages and table turns with SpotOn.`,
  businessName: "Palm House Hospitality",
  industry: "Restaurant, casual dining, bars & nightclubs",
  location: "San Francisco, CA",
  products: [spotonProducts.RESTAURANT, spotonProducts.LABOR_MANAGEMENT],
  description:
    "Co-founded by 3rd generation restaurateur Benson Wang and SF nightclub owner Anderson Pugash, Palm House Hospitality includes restaurants (Palm House, The Dorian), nightclubs (Bergerac, Audio) and hospitality (Flamingo Resort and event space) venues. Palm House Hospitality is a data-driven business, so they looked for a tech partner equally committed to using data to problem solve and drive success. In SpotOn, they found tech to boost operational efficiency and people who share the same hospitality ethos.",
  quoteData: {
    imgName: "palm-house.png",
    quote: `“The biggest highlight of working with SpotOn has been knowing that the person sitting across the table actually understands what we're doing and they care.”`,
    personName: "Benson Wang",
    personTitle: "Chief, Palm House Hospitality",
  },
  goal: `Overseeing five hospitality concepts takes skill. Palm House Hospitality co-owner Benson Wang was searching for a cloud-based point-of-sale system that wouldn’t require a costly and stressful transition. He needed a tech stack that would accommodate different service styles for his different locations and provide him with a 360-degree view of his business anytime, anywhere.`,
  solution: `With SpotOn Serve handhelds, Palm House Hospitality has increased table turns, check averages, and server tips. Accessible cloud-based reporting makes it easier to identify these operational improvements. With SpotOn Teamwork, Palm House Hospitality servers get their tips paid out quickly and accurately and managers save time on calculation and distribution. Wang can focus on creating experiences and bringing people together with the peace of mind that the SpotOn team understands his goals and has his back.`,
  results: {
    title: `The results`,
    stats: [
      { title: `30%`, description: `Increase in check averages` },
      {
        title: `10%`,
        description: `Increase in tips`,
      },
      {
        title: `1-2 hours`,
        description: `Saved per day using SpotOn Teamwork`,
      },
    ],
  },
};

export const relatedSuccessStories = [
  {
    title: "Creating better work experiences with SpotOn Teamwork",
    imageName: "the-bungalow-kitchen.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-08-18",
    href: "/case-studies/the-bungalow-kitchen",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.GET_SMARTER_DATA,
      resourceGoals.MANAGE_AND_RETAIN_EMPLOYEES,
    ],
  },
  {
    title: "Fewer spreadsheets. More teamwork",
    imageName: "guisto-newport.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-12-02",
    href: "/case-studies/guisto-newport",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.GET_SMARTER_DATA,
      resourceGoals.MANAGE_AND_RETAIN_EMPLOYEES,
    ],
  },
  {
    title: "Managing 3 popular Japanese restaurants",
    imageName: "momoya-with-logo.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-07-22",
    href: "/case-studies/momoya",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.GET_SMARTER_DATA,
      resourceGoals.CONNECT_AND_SELL_ONLINE,
    ],
  },
];
